<template>
  <div class="map">
    <ClientOnly v-if="mapReady">
      <yandex-map
        :settings="settings"
        :options="options"
        :coords="coords"
        :controls="[]"
        zoom="14"
        @click="getLocation"
        @map-was-initialized="init"
      >
        <ymap-marker
          v-for="(m, i) in currentMarkers"
          :key="m.id"
          :marker-id="m.id"
          :icon="icon"
          :coords="m.coords"
        />
      </yandex-map>
    </ClientOnly>
  </div>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import ClientOnly from "vue-client-only";

export default {
  name: "MapComponent",
  props: {
    addresses: Array,
    active: Object,
    // markers: Array,
  },
  data() {
    return {
      map: null,
      mapReady: false,
      markers: [],
      coords: [42.967631, 47.503442],
      bounds: null,
      marker: null,
      address: null,
      premiseNumber: null,
      settings: {
        apiKey: "c5ba8ad1-d002-49b3-ae67-db98ac46c0ed",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/static/svg/map-marker.svg",
        imageSize: [64, 64],
        imageOffset: [-18, -30],
      },
      options: {
        suppressMapOpenBlock: true,
        suppressObsoleteBrowserNotifier: true,
        yandexMapDisablePoiInteractivity: false,
      },
    };
  },
  beforeMount() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      if (this.addresses && this.addresses.length) {
        window.ymaps.ready(() => {
          this.markers = JSON.parse(JSON.stringify(this.addresses));
          this.markers.forEach((m) => {
            window.ymaps.geocode(m.address).then((res) => {
              m.coords = res.geoObjects.get(0).geometry.getCoordinates();
              // m.coords = [42.968601, 47.540049];
            });
          });
          // видимо запросы на геокодер не успевают сработать, поэтому добавил задержку
          setTimeout(() => {
            this.mapReady = true;
          }, 300);
        });
      } else {
        window.ymaps.ready(this.init);
        this.mapReady = true;
      }
    });
  },
  computed: {
    currentMarkers() {
      if (this.active) {
        let active = this.markers.find((m) => m.id === this.active.id);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.coords = active.coords;
        return [active];
      }
      return this.markers;
    },
  },
  methods: {
    getLocation(e, coords) {
      this.coords = e ? e.get("coords") : coords;
      // Если метка уже создана – просто передвигаем ее.
      if (this.marker) {
        this.marker.geometry.setCoordinates(this.coords);
      }
      // Если нет – создаем.
      else {
        this.marker = this.createPlacemark(this.coords);
        this.map.geoObjects.add(this.marker);
        // Слушаем событие окончания перетаскивания на метке.
        this.marker.events.add("dragend", () => {
          this.getAddress(this.marker.geometry.getCoordinates());
        });
      }
      this.getAddress(this.coords);
    },
    createPlacemark(coords) {
      return new window.ymaps.Placemark(
        coords,
        {
          iconLayout: "default#image",
        },
        {
          iconLayout: "default#image",
          imageHref: "/static/svg/map-marker.svg",
          iconImageSize: [0, 0],
        }
      );
    },
    // Определяем адрес по координатам (обратное геокодирование).
    getAddress(coords) {
      window.ymaps.geocode(coords).then((res) => {
        let firstGeoObject = res.geoObjects.get(0);
        this.premiseNumber = firstGeoObject.getPremiseNumber();
        this.address = firstGeoObject.getAddressLine();
        this.search = this.address;
      });
    },
    init(map) {
      this.map = map;
    },
  },
  components: {
    yandexMap,
    ymapMarker,
    ClientOnly,
  },
};
</script>

<style lang="stylus">
.map {
  width: 100%
  height: 100%
  border-radius var(--big-radius)
  overflow hidden

  .ymap-container {
    width 100%
    height 100%
  }
}
</style>
