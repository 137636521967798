<template>
  <main class="about-page page">
    <div class="page__inner">
      <SectionComponent>
        <h2>Контакты и адреса</h2>
        <ShopsLocationComponent />
        <AboutContactsComponent />
      </SectionComponent>
    </div>
  </main>
</template>

<script>
import ShopsLocationComponent from "./components/ShopsLocationComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import AboutContactsComponent from "./components/AboutContactsComponent.vue";
export default {
  name: "index",
  components: { AboutContactsComponent, SectionComponent, ShopsLocationComponent },
  metaInfo: {
    title: "Контакты и адреса",
  },
};
</script>

<style lang="stylus">
.about-page{
  h2{
    font-weight: 500;
    font-size: 2em;
    line-height: 120%;
    color: var(--gray-900);
    margin 0

    +below(628px) {
      font-size: 1.5em
    }
  }
}
</style>
