<template>
  <section class="shops-location">
    <div class="shops-location__addresses-wrap">
      <ul class="shops-location__addresses">
        <li class="shops-location__addresses-item" v-for="(item, i) in shops" :key="i">
          <a
            @click.prevent="setActive(item)"
            class="shops-location__address no-hover"
            :class="{ 'shops-location__address--active': isActive(item) }"
            href="#"
          >
            <div class="shops-location__address--left">
              <span class="shops-location__address-title">{{ item.address }}</span>
              <span class="shops-location__address-value">{{ item.phone }}</span>
            </div>

            <RemixIconComponent category="system" name="arrow-right-s-line" />
          </a>
        </li>
      </ul>
    </div>
    <div class="shops-location__map">
      <MapComponent :addresses="shops" :active="active" />
    </div>
  </section>
</template>

<script>
import MapComponent from "components/MapComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "ShopsLocationComponent",
  components: { RemixIconComponent, MapComponent },
  data() {
    return {
      active: undefined,
    };
  },
  methods: {
    setActive(item) {
      this.active = item;
    },
    isActive(item) {
      return JSON.stringify(item) === JSON.stringify(this.active);
    },
  },
  computed: {
    shops() {
      return this.$store.state.global.shops;
    },
  },
};
</script>

<style lang="stylus">
.shops-location {
  display grid
  grid-template-columns 424fr 872fr
  grid-gap 24px
  border-radius var(--big-radius)
  background var(--white)
  +below(990px) {
    grid-template-columns 1fr
  }
  +below(520px) {
    padding 15px
  }

  &__addresses-wrap {
    position relative

    &::after {
      content ""
      display block
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%);
      height 48px
      position absolute
      left 0
      right 0
      bottom 0
      pointer-events none
    }

    .title {
      margin-bottom 30px
    }
  }

  &__addresses {
    display flex
    flex-direction column
    gap: 10px
    max-height 376px
    overflow auto
    padding-right 10px
    padding-bottom 48px

    &-item {
      flex-shrink 0
      display flex
      flex-direction column
    }
  }

  &__address {
    border: 1px solid var(--border-color-2)
    border-radius: 16px;
    padding 16px
    display flex
    align-items: center;
    justify-content: space-between;
    gap: 1px

    &:hover {
      border-color var(--main)
    }

    &--active {
      background var(--gray)
    }

    &--left{
      display flex
      flex-direction column
      gap 4px
    }
    svg{
      width: 20px;
      height: 20px;
    }

    &-title {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 140%;
      color: var(--gray-900);
    }

    &-value {
      font-weight: 400;
      font-size: 0.75em
      line-height: 120%;
      color: var(--gray-900);
    }
  }

  &__map {
    .ymap-container {
      height 376px
      //+below(990px) {
      //  height 500px
      //}
      //+below(860px) {
      //  height 420px
      //}
    }
  }
}
</style>
