<template>
  <div class="about-contacts">
    <div class="about-contacts--left">
      <h2>Контакты</h2>
      <div v-if="socials && socials.length" class="about-contacts__item">
        <span class="about-contacts__item-title">Соц.сети</span>
        <div class="about-contacts__item-socials">
          <div v-for="(item, i) in socials" :key="i" class="about-contacts__item-socials-item">
            <a :href="item.link" target="_blank" rel="nofollow">
              <!--            <figure v-html="item.icon" />-->
              <ImgComponent :img="item.icon" width="50" height="50" contain />
            </a>
          </div>
        </div>
      </div>
      <div v-if="phones && phones.length" class="about-contacts__item about-contacts__item--phones">
        <span class="about-contacts__item-title">Номера телефонов</span>
        <div class="about-contacts__item-feedback">
          <div v-for="(item, i) in phones" :key="i">
            <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div v-if="emails && emails.length" class="about-contacts__item">
        <span class="about-contacts__item-title">Электронная почта</span>
        <div class="about-contacts__item-feedback">
          <div v-for="(item, i) in emails" :key="i">
            <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <AboutFormComponent />
  </div>
</template>

<script>
import AboutFormComponent from "./AboutFormComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "AboutContactsComponent",
  components: { ImgComponent, AboutFormComponent },
  data() {
    return {};
  },
  computed: {
    socials() {
      return this.$store.state.global.socials || [];
    },
    phones() {
      return this.$store.state.global.phones || [];
    },
    emails() {
      return this.$store.state.global.emails || [];
    },
  },
};
</script>

<style lang="stylus">
.about-contacts{
  display grid
  grid-template-columns 424fr 872fr
  grid-gap 24px

  +below(990px) {
    grid-template-columns 1fr
  }

  &--left{
    display flex
    flex-direction column

    :nth-child(4){
      border-bottom: none
    }
  }

  &__item{
    display flex
    flex-direction column
    padding 32px 0
    border-bottom: 1px solid var(--border-color-2);

    &--phones &-feedback {
      a {
        color var(--gray-900)
        text-decoration none
      }
    }

    &-title{
      font-weight: 500;
      font-size: 1.25em;
      line-height: 120%;
      text-align: justify;
      color: var(--gray-900);
      margin-bottom 16px
    }

    &-socials{
      display flex
      margin-top 16px
      gap 20px

      &-item{
        display flex
        justify-content: center;
        align-items: center;

        a {
          display flex
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          border-radius 50%
          overflow hidden

          &:hover {
            background var(--green-50)
          }
        }

        svg path{
          fill var(--gray-3)
        }
      }
    }
    &-feedback{
      display flex
      flex-direction column
      gap 8px
      div{
        display flex
        align-items: baseline;
        gap 8px
      }
      span{
        font-weight: 500;
        font-size: 1em;
        line-height: 140%;
        text-align: justify;
        color: var(--gray-900);
      }

      p{
        font-weight: 400;
        font-size: 0.875em;
        line-height: 20px;
        text-align: justify;
        color: var(--gray-700);
      }

      a{
        font-weight: 500;
        font-size: 1em;
        line-height: 140%;
        text-align: justify;
        text-decoration-line: underline;
        color: #308BDF;

      }
    }
  }
}
</style>
