<template>
  <div class="about-form">
    <h2>Всегда рады помочь</h2>
    <span class="about-form__subtitle">Форма помощи</span>
    <div class="about-form__top">
      <InputComponent type="text" placeholder="Имя" title="Имя" />
      <InputComponent type="text" placeholder="Электронная почта" title="Электронная почта" />
    </div>
    <TextareaComponent type="text" placeholder="Опишите ваш вопрос" title="Опишите ваш вопрос" />
    <div class="about-form__bottom">
      <button @click="openModal()" class="btn btn--main btn--md">Оставить заявку</button>
      <span>
        Нажимая на кнопку, вы соглашаетесь с
        <router-link :to="{ name: 'privacy-police' }">политикой приватности</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import TextareaComponent from "components/TextareaComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
export default {
  name: "AboutFormComponent",
  components: { TextareaComponent, InputComponent },
  methods: {
    openModal() {
      this.$store.state._modals.push({
        component: AlertModal,
        options: {
          title: "Заявка отправлена",
          message: "Ваша заявка принята",
        },
      });
    },
  },
};
</script>

<style lang="stylus">
.about-form{
  display flex
  flex-direction column
  background-image url("/static/images/help-bg.png")
  background-size cover
  justify-content center
  background-color: var(--white-3);
  border-radius: 32px;
  padding 32px
  gap 16px

  +below(628px) {
    padding 30px 16px
  }

  &__top{
    display grid
    grid-template-columns 1fr 1fr
    gap 24px

    +below(628px) {
      grid-template-columns 1fr
    }
  }

  &__subtitle{
    font-weight: 500;
    font-size: 1.25em;
    line-height: 120%;
    text-align: justify;
    color: var(--gray-900);

    +below(628px) {
      font-size 0.875rem
    }
  }
  &__bottom{
    display flex
    gap 16px
    align-items: center;

    +below(1050px) {
      flex-direction column
      align-items flex-start
    }

    span{
      font-weight: 400;
      font-size: 0.875em;
      line-height: 140%;
      color: var(--gray-900);
    }
    a{
      color var(--blue)
      text-decoration underline
    }
  }
}
</style>
